const BASE_URL = "https://randwest-api.performance.africa/api";
const VERSION = "v1";
const API_BASE = `${BASE_URL}/${VERSION}`;

export const API_ROUTES = {
    AUTHENTICATION: {
        LOGIN: `${API_BASE}/users/login`,
        PASSWORD_TOKEN: `${API_BASE}/users/password/token`,
        FORGOT_PASSWORD: `${API_BASE}/users/password/reset`,
    },
    SECTION: {
        SECTIONS: `${API_BASE}/programmes`,
        SECTION_DETAIL: function (programme_id) {
            return `${API_BASE}/programmes/${programme_id}`;
        },
        SECTIONS_BY_KPA: function (kpa_id) {
            return `${API_BASE}/programmes/kpa/${kpa_id}`;
        },
        SECTIONS_BY_KPACATEGORY: function (kpa_id, category_id) {
            return `${API_BASE}/programmes/kpa/${kpa_id}/category/${category_id}`;
        },
        SECTIONS_BY_USER: function (user_id) {
            return `${API_BASE}/programmes/user/${user_id}`;
        },
        SECTIONS_BY_CATEGORY: function (category_id) {
            return `${API_BASE}/programmes/category/${category_id}`;
        },
        SECTIONS_BY_TYPE: function (type_id) {
            return `${API_BASE}/programmes/type/${type_id}`;
        },
        SECTIONS_BY_DEPARTMENT: function (department_id) {
            return `${API_BASE}/programmes/department/${department_id}`;
        },
        SECTION_APPROVAL: function (programme_id) {
            return `${API_BASE}/programmes/approval/${programme_id}`;
        },
        SECTION_EXTENTION: function (programme_id) {
            return `${API_BASE}/programmes/extension/${programme_id}`;
        },
        SECTION_REOPEN: function (programme_id) {
            return `${API_BASE}/programmes/open/${programme_id}`;
        },
        CATEGORIES: `${API_BASE}/metadata/programme/categories`,
        CATEGORY_DETAIL: function (category_id) {
            return `${API_BASE}/metadata/programme/categories/${category_id}`;
        },
        TYPES: `${API_BASE}/metadata/programme/types`,
        TYPE_DETAIL: function (type_id) {
            return `${API_BASE}/metadata/programme/types/${type_id}`;
        },
        TAX_TYPES: `${API_BASE}/metadata/programme/tax_types`,
        TAX_TYPE_DETAIL: function (tax_type_id) {
            return `${API_BASE}/metadata/programme/tax_types/${tax_type_id}`;
        },
        DEPARTMENTS: `${API_BASE}/metadata/programme/departments`,
        DEPARTMENT_DETAIL: function (department_id) {
            return `${API_BASE}/metadata/programme/departments/${department_id}`;
        },
        INTERNAL_UNITS: `${API_BASE}/metadata/programme/internal_units`,
        INTERNAL_UNIT_DETAIL: function (internal_unit_id) {
            return `${API_BASE}/metadata/programme/internal_units/${internal_unit_id}`;
        },
        IMPACT: `${API_BASE}/metadata/programme/impact`,
        IMPACT_DETAIL: function (impact_id) {
            return `${API_BASE}/metadata/programme/impact/${impact_id}`;
        },
        GRAPHS: {
            KPA: {
                DEPARTMENT: function (kpa_id) {
                    return `${API_BASE}/programmes/graphs/programme/department/${kpa_id}`;
                },
                CATEGORY: function (kpa_id) {
                    return `${API_BASE}/programmes/graphs/programme/category/${kpa_id}`;
                },
                TYPE: function (kpa_id) {
                    return `${API_BASE}/programmes/graphs/programme/type/${kpa_id}`;
                },
                SECTION_BY_PROGRESS: function (kpa_id) {
                    return `${API_BASE}/programmes/graphs/programme/progress/${kpa_id}`;
                },
            },
            DEPARTMENT: `${API_BASE}/programmes/graphs/programme/department`,
            CATEGORY: `${API_BASE}/programmes/graphs/programme/category`,
            TYPE: `${API_BASE}/programmes/graphs/programme/type`,
            PROJECT_TYPE: `${API_BASE}/programmes/graphs/project/type`,
            PROJECT_SUPPORT: `${API_BASE}/programmes/graphs/project/area`,
            PROJECT_BY_DEPARTMENT: `${API_BASE}/projects/graphs/partners`,
            PROJECT_BY_ACTIVITY: `${API_BASE}/projects/graphs/activity`,
            PROJECT_ACTIVE_INACTIVE: `${API_BASE}/projects/graphs/closed`,
            PROJECT_BY_PROGRESS: `${API_BASE}/projects/graphs/progress`,
            PROJECT_BY_TARGET: `${API_BASE}/projects/graphs/target`,
            SECTION_BY_PROGRESS: `${API_BASE}/programmes/graphs/progress`,
        },
    },
    PROJECT: {
        PROJECTS: `${API_BASE}/projects`,
        PROJECT_DETAIL: function (project_id) {
            return `${API_BASE}/projects/${project_id}`;
        },
        PROJECTS_BY_SECTION: function (programme_id) {
            return `${API_BASE}/projects/programme/${programme_id}`;
        },
        PROJECTS_BY_USER: function (user_id) {
            return `${API_BASE}/projects/user/${user_id}`;
        },
        PROJECTS_BY_YEAR: function (year) {
            return `${API_BASE}/projects/year/${year}`;
        },
        PROJECTS_BY_YEAR_KPA: function (year, kpa_id) {
            return `${API_BASE}/projects/year-kpa/${year}/${kpa_id}`;
        },
        PROJECTS_BY_KPI: function (kpa_id) {
            return `${API_BASE}/projects/indicator/${kpa_id}`;
        },
        PROJECTS_TID: function (project_id) {
            return `${API_BASE}/tid/project/${project_id}`;
        },
        TYPES: `${API_BASE}/metadata/project/types`,
        TYPE_DETAIL: function (type_id) {
            return `${API_BASE}/metadata/project/types/${type_id}`;
        },
        ACTIVITY: `${API_BASE}/metadata/project/activity`,
        ACTIVITY_DETAIL: function (activity_id) {
            return `${API_BASE}/metadata/project/activity/${activity_id}`;
        },
        AREAS_OF_SUPPORT: `${API_BASE}/metadata/project/areas_of_support`,
        AREAS_OF_SUPPORT_DETAIL: function (areas_of_support_id) {
            return `${API_BASE}/metadata/project/areas_of_support/${areas_of_support_id}`;
        },
        PARTNERS: `${API_BASE}/metadata/project/partners`,
        PARTNER_DETAIL: function (partner_id) {
            return `${API_BASE}/metadata/project/partners/${partner_id}`;
        },
        NDP_CHAPTERS: `${API_BASE}/metadata/project/ndpchapter`,
        NDP_CHAPTERS_DETAIL: function (ndp_chapter_id) {
            return `${API_BASE}/metadata/project/ndpchapter/${ndp_chapter_id}`;
        },
        IMPACT: `${API_BASE}/metadata/project/impact`,
        IMPACT_DETAIL: function (impact_id) {
            return `${API_BASE}/metadata/project/impact/${impact_id}`;
        },
        RISK: `${API_BASE}/risk`,
        RISK_DETAIL: function (risk_id) {
            return `${API_BASE}/risk/${risk_id}`;
        }
    },
    TASKS: {
        TASKS: `${API_BASE}/tasks`,
        TASK_DETAIL: function (task_id) {
            return `${API_BASE}/tasks/${task_id}`;
        },
        TASKS_BY_PROJECT: function (project_id) {
            return `${API_BASE}/tasks/projects/${project_id}`;
        },
        TASKS_BY_USER: function (user_id) {
            return `${API_BASE}/tasks/user/${user_id}`;
        },
        SUBTASKS: `${API_BASE}/subtasks`,
        SUBTASK_DETAIL: function (subtask_id) {
            return `${API_BASE}/subtasks/${subtask_id}`;
        },
        SUBTASKS_BY_TASK: function (task_id) {
            return `${API_BASE}/subtasks/tasks/${task_id}`;
        },
    },
    COMMENTS: {
        COMMENTS: `${API_BASE}/comments`,
        COMMENT_DETAIL: function (comment_id) {
            return `${API_BASE}/comments/${comment_id}`;
        },
        TASKS: function (task_id) {
            return `${API_BASE}/comments/tasks/${task_id}`;
        },
        PROJECTS: function (project_id) {
            return `${API_BASE}/comments/projects/${project_id}`;
        },
        INDICATOR: function (indicator_id) {
            return `${API_BASE}/comments/indicators/${indicator_id}`;
        },
    },

    AUDIT: {
        FINDINGS: `${API_BASE}/audit-findings`,
        FINDINGS_DETAIL: function (finding_id) {
            return `${API_BASE}/audit-findings/${finding_id}`;
        },
        FINDINGS_BY_INDICATOR: function (indicator_id) {
            return `${API_BASE}/audit-findings/indicator/${indicator_id}`;
        },
    },

    CHALLENGES: {
        CHALLENGES: `${API_BASE}/challenges`,
        CHALLENGE_DETAIL: function (challenge_id) {
            return `${API_BASE}/challenges/${challenge_id}`;
        },
        TASKS: function (task_id) {
            return `${API_BASE}/challenges/tasks/${task_id}`;
        },
        PROJECTS: function (project_id) {
            return `${API_BASE}/challenges/projects/${project_id}`;
        },
    },

    TID: {
        TID: `${API_BASE}/tid`,
        TID_DETAIL: function (tid_id) {
            return `${API_BASE}/tid/${tid_id}`;
        },
        TASKS: function (task_id) {
            return `${API_BASE}/tid/tasks/${task_id}`;
        },
        PROJECTS: function (project_id) {
            return `${API_BASE}/tid/projects/${project_id}`;
        },
    },

    ATTACHMENTS: {
        ATTACHMENTS: `${API_BASE}/attachments`,
        ATTACHMENT_DETAIL: function (attachment_id) {
            return `${API_BASE}/attachments/${attachment_id}`;
        },
        TASKS: function (task_id) {
            return `${API_BASE}/attachments/tasks/${task_id}`;
        },
        PROJECTS: function (project_id) {
            return `${API_BASE}/attachments/projects/${project_id}`;
        },
    },
    CONTENT: {
        TERM: `${API_BASE}/content/terms`,
        TERM_DETAIL: function (term_id) {
            return `${API_BASE}/content/terms/${term_id}`;
        },
        KPA: `${API_BASE}/content/kpa`,
        KPA_DETAIL: function (kpa_id) {
            return `${API_BASE}/content/kpa/${kpa_id}`;
        },
        KPA_BY_TERM: function (term_id) {
            return `${API_BASE}/content/kpa/term/${term_id}`;
        },
        KPI: `${API_BASE}/content/kpi`,
        KPI_DETAIL: function (kpi_id) {
            return `${API_BASE}/content/indicators/${kpi_id}`;
        },
        KPI_BY_KPA: function (kpa_id) {
            return `${API_BASE}/content/kpi/kpa/${kpa_id}`;
        },
        KPA_INDICATOR: function (indicator_id) {
            return `${API_BASE}/content/kpa-indicators/${indicator_id}`;
        },
        KPI_INDICATOR: function (indicator_id) {
            return `${API_BASE}/content/kpa-indicators/${indicator_id}`;
        },
        USER_KPAS: `${API_BASE}/content/users/kpa`,
        REGION: `${API_BASE}/content/region`,
        PRIORITY: `${API_BASE}/content/priority`,
        STATUS: `${API_BASE}/content/status`,
        DOWNLOAD: `${API_BASE}/content/download`,
    },
    METADATA: {
        UOM: `${API_BASE}/metadata/uom`,
        UOM_DETAIL: function (uom_id) {
            return `${API_BASE}/metadata/uom/${uom_id}`;
        },
        SECTION: {
            CATEGORIES: `${API_BASE}/metadata/programme/categories`,
            CATEGORY_DETAIL: function (category_id) {
                return `${API_BASE}/metadata/programme/categories/${category_id}`;
            },
            TYPES: `${API_BASE}/metadata/programme/types`,
            TYPE_DETAIL: function (type_id) {
                return `${API_BASE}/metadata/programme/types/${type_id}`;
            },
            TAX_TYPES: `${API_BASE}/metadata/programme/tax_types`,
            TAX_TYPE_DETAIL: function (tax_type_id) {
                return `${API_BASE}/metadata/programme/tax_types/${tax_type_id}`;
            },
            DEPARTMENTS: `${API_BASE}/metadata/programme/departments`,
            DEPARTMENT_DETAIL: function (department_id) {
                return `${API_BASE}/metadata/programme/departments/${department_id}`;
            },
            INTERNAL_UNITS: `${API_BASE}/metadata/programme/internal_units`,
            INTERNAL_UNIT_DETAIL: function (internal_unit_id) {
                return `${API_BASE}/metadata/programme/internal_units/${internal_unit_id}`;
            },
            IMPACT: `${API_BASE}/metadata/programme/impact`,
            IMPACT_DETAIL: function (impact_id) {
                return `${API_BASE}/metadata/programme/impact/${impact_id}`;
            },
        },
        PROJECT: {
            TYPES: `${API_BASE}/metadata/project/types`,
            TYPE_DETAIL: function (type_id) {
                return `${API_BASE}/metadata/project/types/${type_id}`;
            },
            ACTIVITY: `${API_BASE}/metadata/project/activity`,
            ACTIVITY_DETAIL: function (activity_id) {
                return `${API_BASE}/metadata/project/activity/${activity_id}`;
            },
            AREAS_OF_SUPPORT: `${API_BASE}/metadata/project/areas_of_support`,
            AREAS_OF_SUPPORT_DETAIL: function (areas_of_support_id) {
                return `${API_BASE}/metadata/project/areas_of_support/${areas_of_support_id}`;
            },
            PARTNERS: `${API_BASE}/metadata/project/partners`,
            PARTNER_DETAIL: function (partner_id) {
                return `${API_BASE}/metadata/project/partners/${partner_id}`;
            },
            NDP_CHAPTERS: `${API_BASE}/metadata/project/ndpchapter`,
            NDP_CHAPTERS_DETAIL: function (ndp_chapter_id) {
                return `${API_BASE}/metadata/project/ndpchapter/${ndp_chapter_id}`;
            },
            IMPACT: `${API_BASE}/metadata/project/impact`,
            IMPACT_DETAIL: function (impact_id) {
                return `${API_BASE}/metadata/project/impact/${impact_id}`;
            }
        },
    },
    USERS: {
        USERS: `${API_BASE}/users`,
        USER_DETAIL: function (user_id) {
            return `${API_BASE}/users/detail/${user_id}`;
        },
        TYPES: `${API_BASE}/users/types`,
        TYPE_DETAIL: function (type_id) {
            return `${API_BASE}/users/types/${type_id}`;
        },
        ACTIVITY: `${API_BASE}/users/activity`,
        ACTIVITY_BY_USER: function (user_id) {
            return `${API_BASE}/users/activity/user/${user_id}`;
        },
        ACTIVITY_BY_TASK: function (task_id) {
            return `${API_BASE}/users/activity/task/${task_id}`;
        },
        PERPROJECTS_BY_GROUP: `${API_BASE}/users/group`,
        PERMISSIONS: `${API_BASE}/users/permissions`,
        PERPROJECTS_BY_USER: function (user_id) {
            return `${API_BASE}/users/permissions/${user_id}`;
        },
        PROJECT_PERPROJECTS_BY_USER: function (project_id, user_id) {
            return `${API_BASE}/users/permissions/custom/project/${project_id}/${user_id}`;
        },
        SECTION_PERPROJECTS_BY_USER: function (programme_id, user_id) {
            return `${API_BASE}/users/permissions/custom/project/${programme_id}/${user_id}`;
        },
        CUSTOM_PERPROJECTS_BY_USER: function (user_id) {
            return `${API_BASE}/users/permissions/custom/user/${user_id}`;
        },
    },
    REPORT: {
        REPORTS: `${API_BASE}/reports`,
        PROJECTS: `${API_BASE}/reports/projects`,
        SECTIONS: `${API_BASE}/reports/programmes`,
        TASKS: `${API_BASE}/reports/tasks`,
    },
};
