import { Routes, Route, Outlet } from "react-router-dom";
import { PORTAL } from "routes/portal";
import Projects from "pages/projects";
import Project from "pages/project";
import Risks from "../pages/risks";

export default function AdHocRoutes() {
    return (
        <>
            <Routes>
                <Route index element={<Risks />} />
                <Route
                    path={PORTAL.PROJECT_RISKS}
                    element={<Risks />}
                />
            </Routes>
            <Outlet />
        </>
    );
}
