import { ApiConsumer } from 'api/ApiConsumer'
import * as Sentry from '@sentry/react'

export const useDataLoaderHook = () => {
  const loadMultiple = (link, setMethod) => {
    ApiConsumer.get(link)
      .then((res) => {
        res.data.sort((a, b) => a.name.localeCompare(b.name))
        let options = res.data.map((opt) => {
          return {
            value: opt.id,
            label: opt.name,
          }
        })
        setMethod(options)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const loadDropdown = (link, setMethod) => {
    ApiConsumer.get(link)
      .then((res) => {
        res.data.sort((a, b) => a.name.localeCompare(b.name))
        setMethod(res.data)
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  return {
    loadMultiple,
    loadDropdown,
  }
}
