import { GLOBAL } from "constants/global";
import {
    format,
    add,
    isAfter,
    intervalToDuration,
    formatDuration,
} from "date-fns";

export const useTextFormatHook = () => {
    const numberFormatter = new Intl.NumberFormat("en-US");
    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "ZAR",
        minimumFractionDigits: 2,
    });

    const convertDateToString = (date) => {
        return new Date(date).toLocaleDateString("en-ZA");
    };

    const getFullMonth = (date) => {
        return new Date(date).toLocaleString("default", { month: "long" });
    };

    const getDate = (date) => {
        const d = new Date(date);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        return `${d.getFullYear()}/${
            month < 10 ? "0" + month.toString() : month
        }/${day < 10 ? "0" + day.toString() : day}`;
    };

    const formatPhoneNumber = (phoneNumberString) => {
        let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = match[1] ? "+1 " : "";
            return [intlCode, match[2], " ", match[3], " ", match[4]].join("");
        }
        return null;
    };

    const getDateTime = (date, time) => {
        const d = new Date(date);
        const t = new Date(time);
        let month = d.getMonth() + 1;
        let day = d.getDate();

        return `${d.getFullYear()}/${
            month < 10 ? "0" + month.toString() : month
        }/${day < 10 ? "0" + day.toString() : day} ${
            t.getHours() < 10 ? "0" + t.getHours().toString() : t.getHours()
        }:${
            t.getMinutes() < 10
                ? "0" + t.getMinutes().toString()
                : t.getMinutes()
        }`;
    };

    const getTime = (date) => {
        const t = new Date(date);
        return `${
            t.getHours() < 10 ? "0" + t.getHours().toString() : t.getHours()
        }:${
            t.getMinutes() < 10
                ? "0" + t.getMinutes().toString()
                : t.getMinutes()
        }`;
    };

    const formatDate = (date) => {
        let cleaned = ("" + date).replace(/\D/g, "");
        let match = cleaned.match(/^(1|)?(\d{4})(\d{2})(\d{2})$/);
        if (match) {
            return [match[2], "/", match[3], "/", match[4]].join("");
        }
        return null;
    };

    const formatTime = (time) => {
        if (time.length < 4) {
            time = `0${time}`;
        }
        return time.substring(0, 2) + ":" + time.substring(2, time.length);
    };

    const normaliseTableData = (tableHeaders, tableData) => {
        tableData.forEach((row, i) => {
            let normalisedData = {};
            tableHeaders.forEach((column) => {
                normalisedData[column.key] =
                    normalisedData[column.key] === undefined
                        ? row[column.key]
                        : normalisedData[column.key];

                if (column.key === "name")
                    normalisedData["name"] =
                        row.first_name !== undefined &&
                        row.last_name !== undefined
                            ? row.first_name + " " + row.last_name
                            : row.name + `${row.closed ? ` (CLOSED)` : ``}`;

                if (column.key === "duration") {
                    normalisedData[column.key] = formatDuration(
                        intervalToDuration({
                            start: new Date(row.start_date),
                            end: add(new Date(row.end_date), { days: 1 }),
                        })
                    );

                    normalisedData["start_date"] = format(
                        new Date(row.start_date),
                        "dd MMM yyyy"
                    );

                    normalisedData["end_date"] = format(
                        new Date(row.end_date),
                        "dd MMM yyyy"
                    );
                }

                if (column.key === "closed") {
                    normalisedData["closed"] = `${format(
                        new Date(row.created),
                        "dd MMM yyyy"
                    )} by ${
                        row.creator.first_name + " " + row.creator.last_name
                    }`;
                }

                if (
                    typeof normalisedData[column.key] === "object" &&
                    (column.key === "user_type" ||
                        column.key === "type" ||
                        column.key === "ndp_chapter" ||
                        column.key === "kpa" ||
                        column.key === "programme" ||
                        column.key === "project")
                )
                    normalisedData[column.key] = row[column.key]?.name;

                if (
                    column.key === "indicator" &&
                    row?.indicator?.indicator_name !== undefined
                ) {
                    normalisedData["indicator"] =
                        row?.indicator?.indicator_name;
                }

                if (
                    column.key === "indicator_umo" &&
                    row?.kpi?.indicator_umo !== undefined
                ) {
                    normalisedData["indicator_umo"] =
                        row?.kpi?.indicator_umo.name;
                }

                if (column.key === "actual" && ( row?.kpi?.indicator_umo.name === "Percentage" || row?.kpi?.indicator_umo.name === "Number")){
                   
                    let umo =
                    normalisedData["indicator_umo"] === "Percentage"
                        ? "%"
                        : "";

                        let q1 = row.quarter1 === null ? 0 : parseInt(row.quarter1)
                        let q2 = row.quarter2 === null ? 0 : parseInt(row.quarter2)
                        let q3 = row.quarter3 === null ? 0 : parseInt(row.quarter3)
                        let q4 = row.quarter4 === null ? 0 : parseInt(row.quarter4)

                        normalisedData["actual"] = (q1+q2+q3+q4)+ umo;
                        normalisedData["variances"] = ((q1+q2+q3+q4) - row?.target) + umo;
                }

                if (column.key === "baseline") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    normalisedData["baseline"] = row?.baseline + umo;
                }

                if (column.key === "target") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    normalisedData["target"] = row?.target + umo;
                }

                if (column.key === "q1_target") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    normalisedData["q1_target"] = row?.quarter1_max + umo;
                }

                if (column.key === "q2_target") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    normalisedData["q2_target"] = row?.quarter2_max + umo;
                }

                if (column.key === "q3_target") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    normalisedData["q3_target"] = row?.quarter3_max + umo;
                }

                if (column.key === "q4_target") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    normalisedData["q4_target"] = row?.quarter4_max + umo;
                }

                if (column.key === "q1_actual") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";

                    let quarter1 = row.quarter1 === null ? "0" : row.quarter1;
                    normalisedData["q1_actual"] = quarter1 + umo;
                }

                if (column.key === "q2_actual") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    let quarter2 = row.quarter2 === null ? "0" : row.quarter2;
                    normalisedData["q2_actual"] = quarter2 + umo;
                }

                if (column.key === "q3_actual") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    let quarter3 = row.quarter3 === null ? "0" : row.quarter3;
                    normalisedData["q3_actual"] = quarter3 + umo;
                }

                if (column.key === "q4_actual") {
                    let umo =
                        normalisedData["indicator_umo"] === "Percentage"
                            ? "%"
                            : "";
                    let quarter4 = row.quarter4 === null ? "0" : row.quarter4;
                    normalisedData["q4_actual"] = quarter4 + umo;
                }

                if (column.key === "kpi") {
                    normalisedData["kpi"] = row?.kpi?.indicator_name;
                }

                if (
                    column.key === "project_idp_ref" &&
                    row?.project?.idp_ref !== undefined
                ) {
                    normalisedData["project_idp_ref"] = row?.project?.idp_ref;
                }
                if (
                    column.key === "project_budget" &&
                    row?.project?.budget !== undefined
                ) {
                    normalisedData["project_budget"] = currencyFormatter
                        .format(row?.project?.budget)
                        .replace(/[ZA\s]/g, "");
                }
                if (
                    column.key === "project_second_budget" &&
                    row?.project?.second_budget !== undefined
                ) {
                    normalisedData["project_second_budget"] = currencyFormatter
                        .format(row?.project?.second_budget)
                        .replace(/[ZA\s]/g, "");
                }
                if (
                    column.key === "project_actual_budget" &&
                    row?.project?.actual_budget !== undefined
                ) {
                    normalisedData["project_actual_budget"] = currencyFormatter
                        .format(row?.project?.actual_budget)
                        .replace(/[ZA\s]/g, "");
                }

                if (column.key === "expenditure") {
                    normalisedData["expenditure"] = currencyFormatter
                        .format(row?.expenditure)
                        .replace(/[ZA\s]/g, "");
                }

                if (
                    //Special case to get programme under task
                    column.key === "programme" &&
                    row?.project?.programme?.name !== undefined
                ) {
                    normalisedData["programme"] = row?.project?.programme?.name;
                }

                if (column.key === "main_correspondent") {
                    normalisedData["main_correspondent"] =
                        row.main_correspondent.first_name +
                        " " +
                        row.main_correspondent.last_name;
                    normalisedData["designation"] =
                        row.main_correspondent.designation;
                    normalisedData["email"] = row.main_correspondent.email;
                    normalisedData["contact_number"] =
                        row.main_correspondent.contact_number;
                    normalisedData["user_type"] =
                        row.main_correspondent.user_type.name;
                }

                if (
                    column.key === "managers" ||
                    column.key === "performance_managers" ||
                    column.key === "responsible_managers" ||
                    column.key === "responsible_person" ||
                    column.key === "reporting_managers"
                ) {
                    normalisedData[column.key] = [];
                    row[column.key].forEach((contact, x) => {
                        normalisedData[column.key][x] =
                            contact.first_name + " " + contact.last_name;
                    });
                }

                if (
                    column.key === "departments" ||
                    column.key === "ndp_chapters"
                ) {
                    normalisedData[column.key] = [];

                    row[column.key] =
                        column.key === "departments" &&
                        row?.project?.departments !== undefined
                            ? row?.project?.departments
                            : row[column.key];

                    row[column.key].forEach((data, x) => {
                        normalisedData[column.key][x] = data.name;
                    });
                }

                if (
                    column.key === "departments" &&
                    row?.project?.departments !== undefined
                ) {
                    normalisedData[column.key] = [];
                    row[column.key].forEach((data, x) => {
                        normalisedData[column.key][x] = data.name;
                    });
                }

                if (column.key === "goals") {
                    normalisedData[column.key] = [];
                    row[column.key].forEach((data, x) => {
                        normalisedData[column.key][x] = [
                            data.strategic_goal,
                            data.goal_statement,
                            data.goal_outcome,
                        ];
                    });
                }

                if (column.key === "deliverables") {
                    normalisedData[column.key] = [];
                    row[column.key].forEach((data, x) => {
                        normalisedData[column.key][x] = [
                            format(
                                new Date(data.deliverable_date),
                                "dd MMM yyyy"
                            ),
                            data.deliverable_description,
                        ];
                    });
                }

                if (
                    column.key === "quarter"
                ) {
                    normalisedData["quarter"] = row?.quarter === 0 || row?.quarter === "0"  ? `Annual/Overall` : `Quarter `+row?.quarter;
                }

                if (column.key === "status") {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.overall_progress < 100
                    ) {
                        normalisedData["status"] = (
                            <span
                                className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag bg-red-800 text-white`}
                            >
                                {"OVERDUE"}
                            </span>
                        );
                    } else {
                        normalisedData["status"] = (
                            <span
                                className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag ${
                                    GLOBAL.SLIDER.filter((x) =>
                                        row.overall_progress <= 100
                                            ? x.MIN <= row.overall_progress &&
                                              x.MAX >= row.overall_progress
                                            : x.MAX > 100
                                    )[0].STYLE
                                } text-white`}
                            >
                                {
                                    GLOBAL.SLIDER.filter((x) =>
                                        row.overall_progress <= 100
                                            ? x.MIN <= row.overall_progress &&
                                              x.MAX >= row.overall_progress
                                            : x.MAX > 100
                                    )[0].NAME
                                }
                            </span>
                        );
                    }
                }

                if (column.key === "progress") {
                    normalisedData["progress"] = (
                        <span
                            className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag ${
                                GLOBAL.SLIDER.filter((x) =>
                                    row.progress <= 100
                                        ? x.MIN <= row.progress &&
                                          x.MAX >= row.progress
                                        : x.MAX > 100
                                )[0].STYLE
                            } text-white`}
                        >
                            {numberFormatter.format(row.progress) + "%"}
                        </span>
                    );
                }

                if (column.key === "created")
                    normalisedData["created"] = format(
                        new Date(row.created),
                        "dd MMM yyyy"
                    );

                if (column.key === "created_by") {
                    normalisedData["created_by"] = 
                        row.created_by.first_name + " " + row.created_by.last_name
                }

                if (column.key === "posted_by") {
                    normalisedData["posted_by"] = 
                        row.posted_by.first_name + " " + row.posted_by.last_name
                }

                if (column.key === "updated")
                    normalisedData["updated"] = format(
                        new Date(row.updated),
                        "dd MMM yyyy"
                    );
            });

            tableData[i]["normalised"] = normalisedData;
        });
        return tableData;
    };

    return {
        convertDateToString,
        formatPhoneNumber,
        getFullMonth,
        getDate,
        getDateTime,
        getTime,
        formatDate,
        formatTime,
        normaliseTableData,
    };
};
