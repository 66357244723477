export default function Risks(){
    const url = "https://openfiles.s3.af-south-1.amazonaws.com/RiskAssessmentReport.xls";

    console.log(url)

    function isMsDoc() {
        const officeDocumentRegex = /\.(docx|doc|pptx|ppt|xlsx|xls)$/i;
        return officeDocumentRegex.test(url);
    }

    return <>
        <div
            className="inline-block w-screen p-2 my-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded h-screen">
            {
                isMsDoc() ? <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`}
                        className='h-screen w-full rounded'/>
                    :
                    <object data={url} type="application/pdf" className='h-screen w-full rounded '/>
            }
        </div>
    </>
}
