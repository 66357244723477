export const PORTAL = {
    LOGIN: `/login`,
    FORGOTPASSWORD: `/forgot-password`,
    DASHBOARD: `/`,
    SEARCH: `/search`,

    SECTIONS: `/sections`,
    SECTIONS_BY_KPA: `/sections/kpa`,
    SECTIONS_BY_USER: `/sections/user`,
    SECTIONS_BY_DEPARTMENT: `/sections/department`,
    SECTIONS_BY_TYPE: `/sections/type`,

    PROJECTS: `/projects`,
    PROJECTS_BY_SECTION: `/projects/section`,
    PROJECTS_BY_USER: `/projects/user`,
    PROJECT: `/projects/project`,
    PROJECT_RISKS: `/project`,
    PERFORMANCE: `/performance`,

    TASKS: `/tasks`,
    TASKS_BY_PROJECT: `/tasks/project`,
    TASKS_BY_USER: `/tasks/user`,
    TASK: `/tasks/task`,

    REPORTS: `/reports`,
    SECTION_REPORTS: `/reports/sections`,
    PROJECT_REPORTS: `/reports/projects`,
    TASK_REPORTS: `/reports/tasks`,

    ADMIN: `/admin`,
    TERMS: `/terms`,
    KPAS_BY_TERM: `/kpas/term`,
    KPAS: `/kpas`,
    KPIS: `/kpis`,
    KPIS_BY_KPA: `/kpis/kpa`,
    IDP_KPIS_BY_KPA: `/idp/kpis/kpa`,
    SDBIP_KPIS_BY_KPA: `/sdbip/kpis/kpa`,
    DEPARTMENTS: `/admin/departments`,
    META: `/admin/meta`,
    USERS: `/admin/users`,
    ACTIVITY: `/admin/activity`,
    RISK: `/admin/risk`,

    SETTINGS: `/settings`,
    THEME: `/settings/theme`,
    COMPLIANCE: `/settings/compliance`,

    NOT_FOUND: `/404`,
};
