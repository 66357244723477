import PA_PIMS_LOGO from "assets/images/RWCLM-logo.png";
import USER_IMAGE_PLACEHOLDER from "assets/images/user_emlm.png";

export const APP_ID = `PAPIMS`;
export const APP_NAME = `Rand West District Municipality PIMS`;
export const APP_LOGO = PA_PIMS_LOGO;
export const APP_LOGO_ALT = `PA PIMS Logo`;
export const APP_USER_IMAGE_PLACEHOLDER = USER_IMAGE_PLACEHOLDER;
export const NOTIFICATIONS_TABLE = "test";
export const NOTIFICATIONS_LIMIT = 10;

if (process.env.NODE_ENV === "production") console.log = function () {};
